import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import logoImage from './assets/cloud.png';
import YandexAuth from './YandexAuth';
import './Menu.css';

const Menu = () => {
  const [showAuthDialog, setShowAuthDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');

  const handleButtonClick = (type) => {
    const isAuthenticated = localStorage.getItem('auth_token');
    
    if (isAuthenticated) {
      if (type === 'platform') {
        window.open('https://platform.primeway.io', '_blank');
      } else if (type === 'documentation') {
        window.open('https://docs.primeway.io', '_blank');
      }
    } else {
      setDialogTitle(type === 'platform' ? 'Access Platform' : 'Access Documentation');
      setShowAuthDialog(true);
    }
  };

  return (
    <>
      <nav className="menu"> 
        <div className="menu-left">
          <span className="text-logo">PrimeWay</span>
          <button 
            className="platform-button"
            onClick={() => handleButtonClick('platform')}
          >
            Platform
          </button>
          <button 
            className="platform-button"
            onClick={() => handleButtonClick('documentation')}
          >
            Documentation
          </button>
        </div>
        <div className="menu-center">
          <img src={logoImage} alt="Logo" className="image-logo" />
        </div>
        <div className="menu-right">
          <YandexAuth />
        </div>
      </nav>

      <Dialog 
        open={showAuthDialog} 
        onClose={() => setShowAuthDialog(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          {dialogTitle}
          <IconButton
            aria-label="close"
            onClick={() => setShowAuthDialog(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            Please authenticate with your Yandex account to access this feature.
          </DialogContentText>
          <YandexAuth />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Menu;