import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { CssBaseline, Box } from '@mui/material';
import LandingPage from './components/LandingPage';
import Cursor from './components/Cursor';
import YandexAuth from './components/YandexAuth';
import AuthCallback from './components/AuthCallback';

// Simple health check component
const HealthCheck = () => {
  React.useEffect(() => {
    console.log('Health check endpoint hit at:', new Date().toISOString());
  }, []);
  
  return Response.from(new Response('healthy', {
    status: 200,
    headers: {
      'Content-Type': 'text/plain'
    }
  }));
};

function Layout() {
  return (
    <Box sx={{ 
      display: 'flex', 
      padding: 0, 
      margin: 0, 
      width: '100%', 
      height: 'auto' 
    }}>
      <Cursor />
      <CssBaseline />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 0,
          background: 'radial-gradient(circle, #7816EF, #140729)',
          margin: 0,
          width: '100%',
          minHeight: '100vh',
        }}
      >
        {/* <YandexAuth />  */}
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/healthz" element={<HealthCheck />} />
          <Route path="/auth/callback" element={<AuthCallback />} />
        </Routes>
      </Box>
    </Box>
  );
}

function App() {
  return (
    <Router>
      <Layout />
    </Router>
  );
}

export default App;