import React, { useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

const YandexAuth = () => {
  const navigate = useNavigate();
  const isInitialized = useRef(false);

  useEffect(() => {
    // Check if user is already authenticated
    const authToken = localStorage.getItem('auth_token');
    if (authToken) {
      navigate('/');
      return;
    }

    // Only initialize once and if YaAuthSuggest is available
    if (isInitialized.current || !window.YaAuthSuggest) {
      return;
    }

    isInitialized.current = true;

    const initYaAuthSuggest = () => {
      window.YaAuthSuggest.init(
        {
          client_id: '12b9a3c475d8488987bd2efadd1181c6',
          response_type: 'token',
          redirect_uri: 'https://primeway.io/auth/callback'
        },
        'https://primeway.io',
        {
          view: 'button',
          parentId: 'yandex-auth-container',
          buttonView: 'main',
          buttonTheme: 'dark',
          buttonSize: 'm',
          buttonBorderRadius: "20",
          buttonIcon: 'yaEng',
        }
      )
      .then(({ handler }) => handler())
      .then(data => {
        console.log('Auth data:', data);
        if (data.access_token) {
          fetch('https://api.primeway.io/auth/yandex', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token: data.access_token })
          })
          .then(response => response.json())
          .then(userData => {
            localStorage.setItem('auth_token', userData.jwt_token);
            navigate('/');
          });
        }
      })
      .catch(error => {
        console.error('Auth error:', error);
      });
    };

    // Small delay to ensure DOM is ready
    setTimeout(() => {
      initYaAuthSuggest();
    }, 100);

    // Cleanup function
    return () => {
      isInitialized.current = false;
    };
  }, [navigate]);

  // Check if user is authenticated before rendering
  const authToken = localStorage.getItem('auth_token');
  if (authToken) {
    return null;
  }

  return (
    <div 
      id="yandex-auth-container" 
      style={{ 
        margin: '20px',
        minWidth: '280px', 
        minHeight: '36px'
      }} 
    />
  );
};

export default YandexAuth;